@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

//home///////////////////
.home {
  display: flex;
  max-height: 100vh;
  max-width: 100%;
  position: relative;
  .image {
    object-fit: contain;
    img {
      filter: grayscale(100%) brightness(10%) sepia(50%) hue-rotate(-70deg)
        saturate(30);
      max-width: 100%;
      max-height: 100vh;
    }
  }

  .text {
    justify-content: center;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 30px;
    left: 0px;

    img {
      width: 200px;
      position: absolute;
      top: 10px;
      right: 20px;
    }
    .trademark {
      font-family: "Open Sans", sans-serif;
      margin: auto;
      font-size: 30px;
    }
    h2 {
      font-family: "Open Sans", sans-serif;
      margin: auto;
      font-size: 70px;
      color: #8e0027;
    }
    h1 {
      font-family: "Open Sans", sans-serif;
      margin: auto;
      font-size: 80px;
    }
    .date {
      font-family: "Open Sans", sans-serif;
      margin: auto;
      font-size: 20px;
      color: #8e0027;
    }
  }
}

////////////////////ABOUT

.about {
  // display: flex;
  display: flex;
  max-height: 100vh;
  max-width: 100%;

  .heading {
    flex-basis: 20%;
    flex-grow: 1;
    display: grid;
    //text-align: center;
    //justify-content: center;
    //margin: auto;
    // width: 100%;
    // margin-right: 25px;
    justify-content: flex-start;
    .about {
      //margin: auto;
      //font-size: 70px;
      margin: auto;
      margin-bottom: 0;
      font-size: 4vw;
    }
    .us {
      margin: auto;
      margin-top: 0;
      margin-left: 0;
      font-size: 4vw;
    }
  }

  .image {
    flex-basis: 30%;
    flex-grow: 1;

    img {
      // height: 100%;
      // max-height: 100vg;
      // width: 100%;
      // max-width: 500px;
      object-fit: contain;
      max-width: 100%;
      // max-height: 100vh;
    }
  }

  .text {
    flex-basis: 40%;
    flex-grow: 1;
    background-color: #8e0027;
    text-align: justify;
    justify-content: center;
    width: 100%;
    //max-width: 500px;
    padding-right: 25px;
    padding-left: 25px;
    h1 {
      margin: 0;
      font-size: 5rem;
      color: white;
    }
    p {
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 1.5;
      color: white;
    }
  }
}

/////////////////Our services///////////////

.ourServices {
  display: flex;

  .container-grid {
    flex: 1;
    flex-grow: 1;
    flex-basis: 40%;

    .container {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto;

      .img {
        img {
          width: 100%;
          max-width: 77vw;
          height: 75%;
          filter: grayscale(100%) brightness(10%) sepia(50%) hue-rotate(-70deg)
            saturate(30);
        }
      }
      .container-box {
        display: flex;
        margin-top: -50vh;
        z-index: 1;
        max-width: 78vw;
        font-family: "Open Sans", sans-serif;
        .box1,
        .box2,
        .box3 {
          padding-left: 1.5em;
          padding-right: 1.5em;
          padding-bottom: 60px;
          h2 {
            color: #8e0027;
          }
          width: 80vw;
        }
        .box1 {
          border: 1px solid #8e0027;
          display: grid;
          margin-left: 3vw;
          margin-right: 3vw;
          background-color: white;

          height: 35vh;
          text-align: center;
        }

        .box2 {
          border: 1px solid #8e0027;
          display: grid;
          margin-left: 3vw;
          margin-right: 3vw;
          background-color: white;

          height: 35vh;
          text-align: center;
        }

        .box3 {
          border: 1px solid #8e0027;
          margin-left: 3vw;
          margin-right: 3vw;
          display: grid;
          background-color: white;

          height: 35vh;
          text-align: center;
        }
      }
    }
  }
  .header {
    text-align: left;
    justify-content: center;
    margin: auto;
    width: 100%;
    margin-right: 25px;
    .text {
      margin: auto;
      font-size: 4vw;
    }
  }
}

////////////////////////////servicesoffer//////////////////

.servicesOffer {
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  .image,
  .first-segment,
  .second-segment {
    flex-grow: 1;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .image {
    flex-basis: 40%;
    flex-grow: 1;
    img {
      height: 100%;
      max-height: 100vh;
      width: 100%;
      max-width: 500px;
      filter: grayscale(100%) brightness(10%) sepia(50%) hue-rotate(-70deg)
        saturate(30);
    }
  }

  .first-segment {
    border-right: 3px solid gray;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 30%;

    .head {
      margin-right: 3vw;
      h1 {
        margin-bottom: 0;
        font-size: 50px;
        font-weight: 700;
      }
      h3 {
        margin-top: 0;
        margin-left: 10px;
        font-size: 25px;
      }
    }
    .text-icons {
      margin-top: 70px;
      margin-right: 25px;
      .text-first {
        display: flex;
      }
      .text-first {
        justify-content: center;
        h1 {
          margin-right: 1vw;
          margin-top: 2vh;
          font-size: 6vh;
        }
      }
    }
  }

  .second-segment {
    justify-content: center;
    flex-grow: 1;
    flex-basis: 30%;
    margin-right: 20px;
    // margin-left: 20px;
    .text-box {
      padding: 15px;
      text-align: justify;
      p {
        margin-top: 0;
        padding-left: 7px;
      }
    }
    .number {
      display: flex;
      margin-bottom: 0;
      .heading {
        margin-bottom: 7px;
        padding-left: 7px;
      }
      .num {
        margin-left: -37px;
        margin-top: 62px !important;
        position: absolute;
      }
    }
    li {
      list-style-type: none;
    }
  }
}
////////////////////////////////////////////

.ourProces {
  display: flex;
  overflow: hidden;
  font-size: 14px;
  .first-segment,
  .second-segment,
  .third-segment,
  .fourth-segment {
    flex-grow: 1;
    flex-basis: 25%;

    justify-content: center;
    align-items: center;
  }

  .second-segment,
  .third-segment,
  .fourth-segment {
    width: 25%;
  }
  img {
    height: 100%;
    max-height: 100vh;

    filter: grayscale(100%) brightness(10%) sepia(50%) hue-rotate(-70deg)
      saturate(30);
  }
  .first-segment {
    justify-content: center;
    text-align: center;
    margin: auto;
  }
  .container {
    display: block;
    .pic-text {
      position: sticky;
      margin-top: -632px;
      color: antiquewhite;
      justify-content: center;
      text-align: center;
      padding-left: 25px;
      padding-right: 25px;
      font-family: "Open Sans", sans-serif;
      svg {
        width: 5em;
        height: 5em;
      }
    }

    p {
      position: relative;
      z-index: 1;
      color: antiquewhite;
    }
  }
  .heading {
    text-align: center;
    justify-content: center;
    margin: auto;

    margin-right: 25px;
    .about {
      margin: auto;
      font-size: 50px;
    }
  }
}

.contactUs {
  display: flex;
  .form .contactImage {
    width: 400px !important;
    filter: none;
  }
  .form {
    text-align: center;
    justify-content: center;
    margin: auto;

    h1 {
      font-size: 70px;
      font-weight: 900 !important;
      color: #8e0027;
    }
    font-family: "Open Sans", sans-serif;
    p {
      font-weight: bolder;
    }
  }
  .sideImage {
    height: 100%;
    max-height: 100vh;

    filter: grayscale(100%) brightness(10%) sepia(50%) hue-rotate(-70deg)
      saturate(30);
  }
}

/////////////////////////////

@media only screen and (max-width: 1260px) {
  .servicesOffer {
    .first-segment {
      margin-left: 50px;
    }
  }
  .about {
    .text p {
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 990px) {
  .servicesOffer {
    font-size: 13px;
  }
  .ourServices {
    font-size: 13px;
  }
  .about {
    font-size: 13px;
  }
}

@media only screen and (max-width: 960px) {
  .home {
    display: grid;
    height: 100vh;
    .image {
      display: none;
    }
  }
  .ab {
    height: 100vh;

    .heading {
      align-items: center;
      justify-content: center;
    }
    .image {
      display: none;
      flex-basis: 0;
    }
    .text p {
      font-size: 20px;
    }
  }
  .ourServices {
    .img {
      display: none;
    }
    height: 100vh;
    align-items: center;
    .container-box {
      margin-top: 0px !important;
      .grid-item {
        margin-left: 10px !important;
        margin-right: 10px !important;
        font-size: 14px;
      }
    }
  }
  .servicesOffer {
    height: 88vh;
    .image {
      display: none;
    }
    .first-segment,
    .second-segment {
      flex-basis: 50%;
    }
    ol {
      font-size: 15px;
    }
  }

  .ourProces {
    height: 90vh;
    justify-content: center;
    align-items: center;
    .container {
      img {
        display: none;
      }
    }
    .pic-text {
      margin-top: 0px !important;
      color: black !important;
      p {
        color: black !important;
      }
    }
  }
  .contactUs {
    height: auto;
    .image {
      display: none;
    }
  }
}

@media only screen and (max-width: 890px) {
  .home {
    display: grid;
    height: auto;
    align-items: center;
    margin-bottom: 80px;
    margin-top: 80px;
    //justify-content: center;
    .image {
      display: none;
    }
    .rpm-logo {
      margin: 0 auto;
      position: relative !important;
      top: 0 !important;
      right: 0 !important;
    }
    .text {
      text-align: center;
      margin-left: 0px;
      justify-content: center;
      display: grid;
    }
    .text h2 {
      font-size: 50px;
    }
  }
  .ab {
    display: grid;
    height: auto;
    margin-bottom: 80px;
    margin-top: 80px;
    .heading {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      .about {
        font-size: 30px;
      }
    }
    .image {
      display: none;
      flex-basis: 0;
    }
    .text {
      background-color: white;
      width: auto;
      h1 {
        display: none;
      }
    }
    .text p {
      color: black;
      font-size: 15px;
      line-height: normal;
      letter-spacing: normal;
    }
  }
  .ourServices {
    display: grid;

    margin-top: 80px;
    .img {
      display: none;
    }
    height: 100vh;
    align-items: center;
    .header {
      display: inline-flex;
      order: 1;
      .text {
        font-size: 30px !important;
        margin: 0;
      }
    }
    .container-grid {
      order: 2;
    }
    .container-box {
      margin-top: 0px !important;
      display: grid !important;
      .grid-item {
        margin-left: 10px !important;
        margin-right: 10px !important;
        font-size: 14px;
        border: none !important;
        h2 {
          margin: auto;
        }
        height: auto !important;
      }
    }
  }
  .servicesOffer {
    display: grid;
    height: auto;
    margin-bottom: 80px;

    .image {
      display: none;
    }
    .first-segment {
      margin-left: auto !important;
    }
    ol {
      font-size: 15px;
    }
  }

  .ourProces {
    display: grid;
    height: auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
    margin-top: 80px;
    .heading {
      display: inline-flex;
      margin-bottom: 40px;
      .about {
        font-size: 30px;
      }
    }

    .second-segment,
    .third-segment,
    .fourth-segment {
      width: 100% !important;
    }
    .container {
      img {
        display: none;
      }

      .pic-text {
        margin-top: 0px !important;
        color: black !important;
        p {
          color: black !important;
        }
      }
    }
    .contactUs {
      display: grid;

      margin-bottom: 80px !important;

      .image {
        display: none;
      }
    }
  }
}
